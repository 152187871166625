<template>
    <div ref="refAppContainer" class="app-container h-100 w-100 no-toolbar">
        <div :id="chartContainerId" class="p-2"></div>
    </div>
</template>

<script setup lang="ts">
import { ref, defineExpose, onMounted, onUnmounted } from 'vue';
import { useDataObjectEventListener } from 'o365-vue-utils';
import { getOrCreateDataObject } from 'o365-dataobject';
import { utils, $t } from "o365-utils";
import { 
    chartSeriesOrderCost, 
    chartSeriesOrderHours, 
    chartSeriesOrderContingency,
    chartSeriesOrderProgress,
    chartSeries,
    chartContingencySeries,
    chartProgressSeries,
    chartHoursSeries, 
    chartPeriodicSeries, 
    chartPeriodicHoursSeries, 
    chartArchiveSeries, 
    chartArchiveHrsSeries, 
    chartBaselineSeries, 
    chartDraftSeries,
    chartDraftPeriodicSeries
} from 'cost.vue.components.CostCurves.SeriesConfig.ts';
import {
    aviw_Cost_WBSCostCurveData_AllLevels_Config,
    aviw_Cost_ProjectBaselineArchives_Config,
    // aviw_Cost_WBSCostCurveData_Contingency_Config,
    aviw_Cost_WBSContingencyData_Config,
    aviw_Cost_WBSProgressData_Config
} from 'cost.vue.components.CostCurves.DataObjectsConfig.ts';
import { dsTree } from 'cost.vue.Tree.WbsTreeConfig.ts';
import { chartControl, chartsControl } from 'cost.vue.components.CostCurves.Control.ts'

var chart:any = null; // main chart object
var chartControlRef:any = null;

const refAppContainer:any = ref(null);

class Series{
    title: string;
    key: string;
    visible: boolean;
}

const props = defineProps({
    chartContainerId: {
        type: String,
        default: 'chartContainer' //have to use different ones, when rendering more than 1 chart in one app
    },
    useTreeNode: { //will result where clause populated with node data from imported tree dataobject, do not use with non libs tree
        type: Boolean,
        default: false
    },
    zoomType: {
        type: String,
        default: 'x'
    },
    chartType: { //initial chartType
        type: String,
        default: 'costs' //hours // progress
    },
    chartSettingsStorage: { //local storage name
        type: String,
        default: 'costCurveChartSettings'
    },
    seriesVisibilityStorage: { //local storage name
        type: String,
        default: 'costCurveChartSeriesVisibility'
    },
    hideChartTypeMenuItems: {
        type: Boolean,
        default: false
    },
    hideHoursMenuItem: {
        type: Boolean,
        default: false
    },
    hideProgressMenuItem: {
        type: Boolean,
        default: false
    },
    hideLegendToggle: { // will hide legend toggle and legend will be always shown
        type: Boolean,
        default: false
    },
    hideLegend: { // will hide legend toggle and all series will become visible
        type: Boolean,
        default: false
    },
    hidePeriodicToggle: { // will hide periodic toggle but not series, use hidePeriodic or excludeSeries
        type: Boolean,
        default: false
    },
    hidePeriodic: { // will hide periodic series and toggle
        type: Boolean,
        default: false
    },
    excludeSeries: { //['PlannedValue','EarnedValue']
        type: Array,
        default: null
    },
    useProjectSettings: { // for future functionality, where series exclusion could be defined in project settings
        type: Boolean,
        default: false
    },
    seriesHiddenByDefault:{
        type: Array,
        default: []
    }
});

const dsWBSCutOffsChart = getOrCreateDataObject(props.chartType === 'contingency' 
    ? aviw_Cost_WBSContingencyData_Config 
    : props.chartType === 'progress' 
    ? aviw_Cost_WBSProgressData_Config
    : aviw_Cost_WBSCostCurveData_AllLevels_Config);

const dsProjectBaselineArchives = getOrCreateDataObject(aviw_Cost_ProjectBaselineArchives_Config);

//--------------------------------------------------------------------------------------------------//
//--------------------------------------------------------------------------------------------------//

const createOrRefreshChart = (pSpecific? : boolean) => {
    //console.log('createOrRefresh called')
    //console.log('component chart type:', props.chartType)
    if (pSpecific) { 
        chartControlRef = chartsControl[props.chartContainerId];
    } else {
        chartControlRef = chartControl; // to be deleted
    }
 
    // Temporary fix: Currently, createOrRefresh triggers all charts created on App, even those not intended to be refreshed or created.
    const container = document.getElementById(props.chartContainerId);
        if (!container) {
        // console.error(`Chart container with ID ${props.chartContainerId} does not exist.`);
        return;
    }
    setWhereClause();
 
    chart = Highcharts.chart(props.chartContainerId, getChartSettings());
    if(!chart)
        return;
    chart.showLoading();
   
    setChartSize();
    chart.setTitle({ text: '' });

    dsWBSCutOffsChart.load(); //check DataLoaded Event
};
 

//override import, do not delete --- this is deprecated !!!!!!!!
chartControl.createOrRefresh = createOrRefreshChart; // to be deleted

//override import, do not delete
chartsControl[props.chartContainerId] = {
    createOrRefresh: () => {createOrRefreshChart(true)},
    whereClause: '',
    definitionParameters: {
        OrgUnit_ID: null,
        WBS_ID: null,
        Import_ID: null,
        FromPreviousCutOff: false,
        ShowAllPeriods: false
    }
} 

const setWhereClause = () => {
    let vWhereClause : Array<string> = [],
        vOrgUnit_ID : Number | null= null,
        vWBS_ID : Number | null = null,
        vFromPreviousCutOff : boolean = false,
        vShowAllPeriods : boolean = false,
        vImport_ID : Number | null = null

    //parameters

    //from import
    if (chartControlRef.definitionParameters.OrgUnit_ID || chartControlRef.definitionParameters.WBS_ID) {
        vOrgUnit_ID = chartControlRef.definitionParameters.OrgUnit_ID || null,
        vWBS_ID = chartControlRef.definitionParameters.WBS_ID || null
    }

    if (chartControlRef.definitionParameters.FromPreviousCutOff) {
        vFromPreviousCutOff = true;
    } 

    if (chartControlRef.definitionParameters.ShowAllPeriods) {
        vShowAllPeriods = true;
    } 

    if (chartControlRef.definitionParameters.Import_ID) {
        vImport_ID = chartControlRef.definitionParameters.Import_ID;
    }

    //from tree
    if (props.useTreeNode) { // get node data from tree
        if(dsTree?.current?.NodeType) {
            if(dsTree.current.NodeType === 'WP' || dsTree.current.NodeType === 'WBS') {
                vOrgUnit_ID = dsTree.current.OrgUnit_ID || null;
                vWBS_ID = dsTree.current.ID || null;
            } else {
                vOrgUnit_ID = dsTree.current.ID || null;
                vWBS_ID = null;
            }
        }
    }

    if (chartConfig.chartType === 'contingency') {
        dsWBSCutOffsChart.recordSource.sqlStatementParameters = { WBS_ID: vWBS_ID ?? null };
    } else if (chartConfig.chartType === 'progress') {
        dsWBSCutOffsChart.recordSource.sqlStatementParameters = { WBS_ID: vWBS_ID ?? null, OrgUnit_ID : vOrgUnit_ID ?? null };
    } else {
        dsWBSCutOffsChart.recordSource.sqlStatementParameters = {
            OrgUnit_ID: vOrgUnit_ID || null,
            WBS_ID: vWBS_ID || null,
            ImportID: vImport_ID || null,
            FromPreviousCutOff: vFromPreviousCutOff || false,
            ShowAllPeriods: vShowAllPeriods || false
        }
    }

    if (chartControlRef?.whereClause) {
        vWhereClause.push(chartControlRef.whereClause);
    }

    dsWBSCutOffsChart.recordSource.whereClause = vWhereClause.join(' AND ');
}

const refreshChart = () => { //cal this when chart exists
    let currentSeries = getCurrentSeries();
    let plotLines: Object[] = [];
    let xCategories = dsWBSCutOffsChart.data.map((row) => utils.formatDate(row.CutOff || row.Cutoff, "MM.yyyy"));
    let xSteps = scaleXAxis(xCategories.length);

    clearChart(chart, currentSeries);
    
    chart.xAxis[0].options.labels.step = xSteps;
    chart.xAxis[0].categories = xCategories;
    chart.options.exporting.chartOptions = {
        xAxis: {
            labels: {
                enabled: true,
                step: xSteps
            },
            categories: xCategories
        }
    }

    dsWBSCutOffsChart.data.forEach(function (pRow, pIndex) {
        //console.log(pRow,'pROW')
        pRow._fields.forEach((i) => {
            if (currentSeries.hasOwnProperty(i.name)) {
                currentSeries[i.name].data.push([pRow[i.name]]);
            }
        })
        
        if (pRow.IsCurrentCutOff) {
            plotLines.push(chart.xAxis[0].addPlotLine({
                value: pIndex,
                color: 'grey',
                label: {
                    text: $t('Current Cutoff'),
                    align: 'centre'
                },
                width: 1,
                id: 'first',
                dashStyle: 'dash'
            }));
        }
    });
    for (var vSeriesKey in currentSeries) {
        if (currentSeries.hasOwnProperty(vSeriesKey)) {
            chart.addSeries(currentSeries[vSeriesKey], false);
        }
    }

    chart.setTitle({ text: dsWBSCutOffsChart.current.WBS });
    chartConfig.title = dsWBSCutOffsChart.current.WBS;

    chart.hideLoading();
    chart.redraw();
};

const getMenuItems = () => {
    let menu = [
        "viewFullscreen",
        "printChart",
        "downloadPNG",
        "downloadJPEG",
        "downloadPDF"
    ];

    if( (!props.hidePeriodicToggle && !props.hidePeriodic) || (!props.hideLegendToggle && !props.hideLegend) ) {
        menu.push("separator");
    }
    if(!props.hideLegendToggle && !props.hideLegend) {
        menu.push("legend");
    }
    if(!props.hidePeriodicToggle && !props.hidePeriodic) {
        menu.push("periodic");
    }
    if (!props.hideChartTypeMenuItems) {
        menu.push("separator", 
            "costs",
            ...(!props.hideHoursMenuItem ? ["hours"] : []), 
        );
    }
    if(props.seriesHiddenByDefault.length > 0) {
        menu.push("separator");
        props.seriesHiddenByDefault.forEach((i:Series)=>{
            menu.push(i.key);
        });
    }
    return menu;
};

const legendButtonDefinition = () => {
    return {
        text: getLegendInitialState() ? $t('Hide Legend') : $t('Show Legend'), //initial
        onclick: function () {
            let legend = chart.legend;
            const vSettings:any = getStorageObj(props.chartSettingsStorage);

            if (legend.display) {
                // Hide the legend
                legend.update({ enabled: false });
                vSettings.showLegend = false;    
            } else {
                // Show the legend
                legend.update({ enabled: true });
                vSettings.showLegend = true;
            }

            chart.exporting.update({ 
                menuItemDefinitions: { 
                    legend: { 
                        text: vSettings.showLegend ? $t('Hide Legend') : $t('Show Legend')
                    } 
                } 
            });

            setStorageObj(props.chartSettingsStorage, vSettings);
        }
    }
};

function getLegendInitialState() {
    if(props.hideLegend) {
        return false;
    }
    if(props.hideLegendToggle) {
        return true;
    }

    const vSettings:any = getStorageObj(props.chartSettingsStorage);
    if(vSettings && vSettings.hasOwnProperty('showLegend')){
        return vSettings.showLegend;
    } else {
        return true; //by default when no stored settings exist, legend is displayed
    }
}

const periodicButtonDefinition = () => {
    return { 
        text: getPeriodicInitialState() ? $t('Hide Periodic') : $t('Show Periodic'), //initial
        onclick: function () {
            const vSettings:any = getStorageObj(props.chartSettingsStorage);
            const vPeriodicAxisIndex = chart?.yAxis ? chart.yAxis.findIndex( axis => axis?.userOptions?.isPeriodic === true ) : null;

            if (vSettings?.showPeriodic) {
                // Hide the periodic values
                vSettings.showPeriodic = false;
                setStorageObj(props.chartSettingsStorage, vSettings);
            } else {
                // Show the periodic values
                vSettings.showPeriodic = true;
                setStorageObj(props.chartSettingsStorage, vSettings);
            }

            createOrRefreshChart(true);
            if(vPeriodicAxisIndex) {
                chart.yAxis[vPeriodicAxisIndex].update({visible: vSettings.showPeriodic}); //must go after redraw
            }

        }
    }
};

function createMenuItemDefinitions(){
    let menuItemDefinitions = {
        legend: legendButtonDefinition(),
        periodic: periodicButtonDefinition(),
        costs: {
            onclick: function () {
                chartConfig.chartType = 'costs';
                createOrRefreshChart(true);
            },
            text: chartConfig.chartType === 'costs' ? '<i class="bi bi-check"></i> Costs' : 'Costs'
        },
        hours: {
            onclick: function () {
                chartConfig.chartType = 'hours';
                createOrRefreshChart(true);
            },
            text: chartConfig.chartType === 'hours' ? '<i class="bi bi-check"></i> Hours' : 'Hours'
        }
    }
    props.seriesHiddenByDefault.forEach((i:Series)=>{
        let updateObj:any = {menuItemDefinitions:{}};
        updateObj.menuItemDefinitions[i.key] = {};
        menuItemDefinitions[i.key] = {
            onclick: function () {
                const vSettings:any = getStorageObj(props.chartSettingsStorage);
                const vSeriesVisibilitySettings = getStorageObj(props.seriesVisibilityStorage)
                vSettings[i.key] = !vSettings[i.key];
                vSeriesVisibilitySettings[i.key] = true;
                updateObj.menuItemDefinitions[i.key].text = vSettings ? i.title +' <i class="bi bi-check"></i>' : i.title;
                chart.exporting.update(updateObj);
                
                setStorageObj(props.chartSettingsStorage, vSettings);
                setStorageObj(props.seriesVisibilityStorage, vSeriesVisibilitySettings);
                createOrRefreshChart(true);
            },
            text: getStorageObj(props.chartSettingsStorage)[i.key] ? i.title +' <i class="bi bi-check"></i>' : i.title
        }
    });
    return menuItemDefinitions;
}

function getPeriodicInitialState() {
    if(props.hidePeriodic) {
        return false;
    }
    if(props.hidePeriodicToggle) {
        return true;
    }

    const vSettings:any = getStorageObj(props.chartSettingsStorage);
    if(vSettings && vSettings.hasOwnProperty('showPeriodic')){
        return vSettings.showPeriodic;
    } else {
        return false; //by default when no stored settings exist, periodic is not displayed
    }
}

function clearChart(pChart, currentSeries) {
    pChart.xAxis[0].removePlotLine("first");

    for (let i = pChart.series.length; i > 0; i--) {
        pChart.series[0].remove(false);
    }
    for (let property in currentSeries) {
        if (currentSeries.hasOwnProperty(property)) {
            currentSeries[property].data = [];
        }
    }
}

const setChartSize = () => {
    let vHeight = refAppContainer?.value?.offsetHeight ? (refAppContainer.value.offsetHeight-10) : 600;
    let vWidth = refAppContainer?.value?.offsetWidth ? (refAppContainer.value.offsetWidth-10) : 992;
    if(chart) {
       chart.setSize(vWidth, vHeight, false); 
    }
}

//--------------------------------------------------------------------------------------------------//
//-----------------------------------           EVENTS          ------------------------------------//
//--------------------------------------------------------------------------------------------------//

function onLegendItemClick(e) {
    const targetSeries = e.target;
    const uniqueName = targetSeries.userOptions.uniqueName;
    const vSeriesVisibility = getStorageObj(props.seriesVisibilityStorage);
    vSeriesVisibility[uniqueName] = !e.target.visible;
    setStorageObj(props.seriesVisibilityStorage, vSeriesVisibility);
}

useDataObjectEventListener(dsProjectBaselineArchives, 'DataLoaded', () => {
    if (chart) {
        refreshChart();
    } else if (document.querySelector(`#${props.chartContainerId}`)) {
        createOrRefreshChart(true);
    }
});


useDataObjectEventListener(dsWBSCutOffsChart, 'DataLoaded', () => {
    let projectId = dsWBSCutOffsChart.current.OrgUnit_ID || '-1';
    dsProjectBaselineArchives.recordSource.whereClause = "Project_ID = " + projectId;
    dsProjectBaselineArchives.load();
});

onMounted(() => {
    const observer = new ResizeObserver( () => {setChartSize() });
    observer.observe(refAppContainer.value);
      
    onUnmounted(() => {
        observer.disconnect();
    });
});

//--------------------------------------------------------------------------------------------------//
//-----------------------------------     SERIES FUNCTIONS      ----------------------------------- //
//--------------------------------------------------------------------------------------------------//

const getCurrentSeries = () => {
    const vSettings:any = getStorageObj(props.chartSettingsStorage);

    const vSeries = {
        ...getSeries(), 
        ...getPeriodicSeries(), 
        ...getArchiveSeries(),
        ...getBaselineSeries(),
    };
    //exclude series
    if(props.useProjectSettings) {
        //get project settings
        //const vTestArray = ['PlannedValue','Booked'];

        // for(var item in vSeries) { 
            // if(vTestArray.indexOf(item) > -1) {
                // delete vSeries[item];
            // }
        // }
        
    } else if(props.excludeSeries && props.excludeSeries.length > 0) {
        for(var item in vSeries) { 
            if(props.excludeSeries.indexOf(item) > -1) {
                delete vSeries[item];
            }
        }
    }
    if(props.seriesHiddenByDefault.length > 0){
        props.seriesHiddenByDefault.forEach((i:Series)=>{
            if(!vSettings[i.key]){
                delete vSeries[i.key];
            }
        })
    }

    // attaching uniqueName depending on key. is needed for saving visibility in storage
    for(var item in vSeries) { 
        if(!vSeries[item].uniqueName) {
            vSeries[item].uniqueName = item;
        }
    }

    if(props.hideLegend) {
        for(var item in vSeries) {
            vSeries[item].visible = true;
        }
    } else {
        //get series visibility from storage
        const vSeriesVisibilitySettings = getStorageObj(props.seriesVisibilityStorage);

        if(vSeriesVisibilitySettings) {
            for(var item in vSeries) {
                let uniqueName = vSeries[item].uniqueName;
                if(vSeriesVisibilitySettings.hasOwnProperty(uniqueName)){
                    vSeries[item].visible = vSeriesVisibilitySettings[uniqueName];
                }
            }
        }
    }

    const vSeriesSorted = sortSeries(vSeries);
    return vSeriesSorted;
}

const sortSeries = (pSeries : Object) : Object => {
    let vSeries = {};

    const vSortOrder = chartConfig.chartType === 'hours' 
        ? chartSeriesOrderHours 
        : chartConfig.chartType === 'contingency' 
        ? chartSeriesOrderContingency
        : chartConfig.chartType === 'progress'
        ? chartSeriesOrderProgress
        : chartSeriesOrderCost;

    vSortOrder.forEach((orderItem, index) => {
        if(pSeries.hasOwnProperty(orderItem)) {
            vSeries[orderItem] = pSeries[orderItem];
        }
    });

    return vSeries;
}

//-----Regular Series

const getSeries = () : Object => {
    let vSeries = {};

    if (chartConfig.chartType === 'hours' ) {
        vSeries = {...chartHoursSeries}
    } else if (chartConfig.chartType === 'contingency') {
        vSeries = {...chartContingencySeries}
    } else if (chartConfig.chartType === 'progress') {
        vSeries = {...chartProgressSeries}
    } else {

        vSeries = {
            ...chartSeries, 
            ...(dsWBSCutOffsChart?.recordSource?.sqlStatementParameters?.ImportID ? chartDraftSeries : null )
        }
    };

    return vSeries;
}

//-----Periodic Series

const getPeriodicSeries = () : Object => {
    if(props.hidePeriodic) return {};

    const vSettings:any = getStorageObj(props.chartSettingsStorage);
    let vSeries = {}

    if(props.hidePeriodicToggle || vSettings?.showPeriodic) {

        if (chartConfig.chartType === 'hours' ) {
            vSeries = {...chartPeriodicHoursSeries}
        } else if (chartConfig.chartType === 'contingency') {
            vSeries = {}; // no periodic for contingency
        } else {
            vSeries = {
                ...chartPeriodicSeries, 
                ...(dsWBSCutOffsChart?.recordSource?.sqlStatementParameters?.ImportID ? chartDraftPeriodicSeries : null )
            }
        };

    }

    return vSeries;
}

//-----Archive

const getArchiveSeries = () : Object => {
    let vSeries = { ...(chartConfig.chartType === 'hours' ? chartArchiveHrsSeries : chartArchiveSeries) };
    removeUnusedArchiveSeries(vSeries);
    renameArchiveSeries(vSeries);
    return vSeries;
}
const getBaselineSeries= () : Object => {
    let vSeries = {...chartBaselineSeries};
    removeUnusedBaselineSeries(vSeries);
    return vSeries;
}

const renameArchiveSeries = (pArchiveSeries: Object) =>{
    dsProjectBaselineArchives.data.forEach(x => {
        const archiveID = x.ArchiveID;

        for (const key in pArchiveSeries) {
            if (archiveID === 1 && key.startsWith("Archive1")) {
                pArchiveSeries[key].name = pArchiveSeries[key].name.replace('Archive1', x.BaselineName + ' - ') ; // Change the name for Archive2
            } else if (archiveID === 2 && key.startsWith("Archive2")) {
                pArchiveSeries[key].name = pArchiveSeries[key].name.replace('Archive2', x.BaselineName + ' - ') ; // Change the name for Archive2
            }
        }
    });
}

const removeUnusedArchiveSeries = (pArchiveSeries: Object) => {
    // Array to store unique ArchiveIDs from dsProjectBaselineArchives.getData()
    const uniqueArchiveIDs = new Set();

    // Iterate over the data from dsProjectBaselineArchives
    dsProjectBaselineArchives.data.forEach(x => {
        uniqueArchiveIDs.add(x.ArchiveID);
    });

    // Iterate over the keys of pArchiveSeries
    for (const key in pArchiveSeries) {
        // Extract the ArchiveID from the key
        const archiveID = key.match(/\d+/)[0]; // Extracts the numeric part from the key

        // Check if the uniqueArchiveIDs set does not contain the ArchiveID
        if (!uniqueArchiveIDs.has(parseInt(archiveID))) {
            // If it doesn't exist, delete the entry from pArchiveSeries
            delete pArchiveSeries[key];
        }
    }
}

const removeUnusedBaselineSeries = (pBaselineSeries: Object) => {
    const baselineTotalExists = dsWBSCutOffsChart.data.some(x => x.OriginalBaselineTotal > 0);
    const baselineValueExists = dsWBSCutOffsChart.data.some(x => x.OriginalBaselineValue > 0);
    const baselineValuePeriodicExists = dsWBSCutOffsChart.data.some(x => x.OriginalBaselineValuePeriodic > 0);

    const vSettings:any = getStorageObj(props.chartSettingsStorage);

    // Iterate over the keys of pBaselineSeries
    for (const key in pBaselineSeries) {
        // If it doesn't exist, delete the entry from pBaselineSeries
        if (!baselineTotalExists && key === 'OriginalBaselineTotal') {
            delete pBaselineSeries[key];
        } else if (!baselineValueExists && key === 'OriginalBaselineValue') {
            delete pBaselineSeries[key];
        } else if ((!baselineValuePeriodicExists || props.hidePeriodic || (!props.hidePeriodicToggle && !vSettings?.showPeriodic)) && key === 'OriginalBaselineValuePeriodic') {
            delete pBaselineSeries[key];
        }
    }
}

//--------------------------------------------------------------------------------------------------//
//-----------------------------------     CHART SETTINGS        ----------------------------------- //
//--------------------------------------------------------------------------------------------------//

const chartConfig = {
    chartType: props.chartType || 'costs', // if empty type was passed
    title:''
}

function getChartSettings(): Object {
    const chartSettings = {
        chart: {
            events: {
                beforePrint() {
                    this.setSize(null, 600, false); // @width, @height, @animation
                },
                afterPrint() {
                    this.setSize(null, null, false);
                }
            },
            zoomType: props.zoomType,
            // scrollablePlotArea: { //only one axis works, not both
                // minHeight: 600,
                // scrollPositionY: 1,
                // minWidth: 300,
                // scrollPositionX: 0,
            // }
        },

        // exporting: {
            // printMaxWidth: 992,
            // sourceWidth: 992,
            // sourceHeight: 600,
            //scale: 1, //default is 2
        // },

        title: {
            text: chartConfig.title || '',
            style: {
                fontSize: '15px',
                fontFamily: 'system-ui'
            }
        },
        accessibility:{
            enabled: false
        },

        yAxis: [
            {
                title: {
                    text: $t('Cumulative')
                },
                min: 0,
            },
            {
                title: {
                    text: $t('Periodic')
                },
                opposite: true,
                visible: getPeriodicInitialState(),
                min: 0,
                isPeriodic: true
            }
        ],

        xAxis: {
            accessibility: {
                rangeDescription: '',
            },
            type: "datetime",
            labels: {
                enabled: true,
                step: 5,
                rotation: 90
            }
        },

        plotOptions: {
            series: {
                events: {
                    legendItemClick: function (e) {
                        //this fires before series visibility is toggled
                        onLegendItemClick(e);
                    }
                },
                marker: {
                    enabled: false
                }
            }
        },

        legend: {
            enabled: getLegendInitialState()
        },

        exporting: {
            printMaxWidth: 992,
            buttons: {
                contextButton: {
                    menuItems: getMenuItems()
                }
            },
            menuItemDefinitions: createMenuItemDefinitions()
        },

        credits: {
            enabled: false
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: "",
        },

    };
    return chartSettings;
}


//--------------------------------------------------------------------------------------------------//
//-----------------------------------           UTILS           ----------------------------------- //
//--------------------------------------------------------------------------------------------------//

function getStorageObj(pStorageName:string) : Object {
    return Object(JSON.parse(localStorage.getItem(pStorageName)) ?? {});
}

function setStorageObj( pStorageName: string, pStorageObj: Object ) {
    if(!pStorageName) return;
    localStorage.setItem( pStorageName, JSON.stringify(pStorageObj) );
}

function scaleXAxis(pDataLength) {
    if (pDataLength < 40) {
        return Math.ceil((pDataLength + 1) / 10);
    } else if (pDataLength < 50) {
        return 6;
    }
    return 12;
}

//--------------------------------------------------------------------------------------------------//

//--------------------------------------------------------------------------------------------------//

defineExpose({
    createOrRefreshChart
}); 

</script>